.ant-upload-select {
  position: relative;
}
.mainclass {
  display: flex;
  justify-content: center;
  overflow: hidden;
  align-items: center;
  width: 100%;
  height: 100%;
}
.camera {
  position: absolute;
  cursor: pointer;
  width: 46.79px;
  height: 46.79px;
  top: 96px;
  right: -3px;
}
.user {
  width: 136.25px;
  height: 136.25px;
  border-radius: 34.4059px;
}
.ant-card-type-inner .ant-card-head-title {
  font-size: 18px !important;
}
.submit-button {
  border-radius: 3px;
  margin-top: 31px;
  background-color: #4e73df;
  border-color: #4e73df;
  color: #fff;
}
.submit-button:hover {
  color: #fff;
  background-color: #2e59d9;
  border-color: #2653d4;
}
.ant-card-bordered {
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15) !important;
  border-radius: 3px;
  border: 1px solid #e3e6f0;
}
.flex-x {
  display: flex;
  margin-top: 10px;
}
.new-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  position: relative;
  cursor: pointer;
}
.inside-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
