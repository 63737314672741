.anticon svg {
  color: linear-gradient(135deg, #fbda61 2.88%, #ffc371 100%);
}
.ant-rate-star-half .ant-rate-star-first {
  color: linear-gradient(180deg, #f4df73 0%, #ffb803 100%);
}
/* .ant-layout-sider {
  background: #343434;
} */
.ant-input:focus {
  border-color: #666666;
  box-shadow: none;
}
.ant-input:hover {
  border-color: #666666;
}
.ant-divider-horizontal {
  margin: 0px;
}
.ant-input:placeholder-shown {
  font-size: 17px;
}
.ant-form-item .ant-mentions,
.ant-form-item textarea.ant-input {
  height: 111px;
}
.antd-menuu {
  background: #343434 !important;
}
.ant-layout-sider {
  background: #343434 !important;
}
.ant-progress-inner {
  font-size: 10px !important;
}
.ant-progress-circle {
  width: 40px !important;
  height: 40px !important;
}
.ant-upload-text {
  font-size: 10px !important;
}
.ant-progress-circle-path {
  stroke: linear-gradient(
    148.54deg,
    #ffe925 20.91%,
    #ffaa39 105.47%
  ) !important;
}

.ant-progress-bg {
  background: linear-gradient(
    148.54deg,
    #ffe925 20.91%,
    #ffaa39 105.47%
  ) !important;
}

.ant-progress-text {
  display: none !important;
}
.ant-carousel .slick-next ::before {
  content: "";
}
.ant-carousel .slick-prev ::before {
  content: "";
}
.site {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white !important;
}

.ant-menu-title-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
.anticon-menu-unfold svg {
  height: 30px;
  margin-left: 10px;
  font-size: 20px;
  margin-top: 15px;
}
.anticon-menu-fold svg {
  height: 30px;
  margin-left: 10px;
  font-size: 20px;
  margin-top: 15px;
}
.ant-upload {
  padding: 0px !important;
}

.ant-layout-header {
  background-color: #ffffff;
}

.header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.otherthings__container {
  width: 500px;
  display: flex;
  justify-content: space-around;
}
.ant-progress-show-info .ant-progress-outer {
  margin-right: 0 !important;
}
