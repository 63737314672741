.border_important {
  background: linear-gradient(
    168.55deg,
    #fbda61 68.9%,
    #fad44c 84.53%,
    #ee7d3e 113.04%,
    #ee7d3e 129.72%
  );
  height: 4px;
  width: 100%;
}
ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: none;
}
