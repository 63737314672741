.swiper-container {
  width: 100%;
  height: 585px;
}
.swiper-wrapper {
  width: 50%;
}
.swiper-slide {
  text-align: center;
  width: auto;
}
.slide-image {
  height: 400px;
  width: auto;
}
.my-gallery figure {
  margin: 0px;
}
.swiper-button-next {
  background-image: url("/src/Assets/community/Totop.svg") !important;
  background-repeat: no-repeat;
  background-size: 28px;
  top: 92%;
  right: 8px;
  background-position: center;
}
.swiper-button-next::after {
  display: none;
}

.swiper-button-prev {
  background-image: url("/src/Assets/community/pstCountPrev.svg") !important;
  background-repeat: no-repeat;
  background-size: 28px;
  top: 92%;
  left: 238px;
  background-position: center;
}
.swiper-button-prev::after {
  display: none;
}
.sarthak img {
  height: 20px;
  width: 20px;
}
.gBIasd {
  width: 62%;
  height: 744px;
}
.react-emoji-picker--wrapper {
  right: 18px;
  height: 195px;
  width: 367px;
}
.dropdown {
  width: 300px;
  border-radius: 10px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  background-color: white;
}

.dropdown-header {
  padding: 15px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dropdown-body {
  padding: 5px;
  border-top: 1px solid #e5e8ec;
  display: none;
}

.dropdown-body.open {
  display: block;
}

.dropdown-item {
  padding: 10px;
}

.dropdown-item:hover {
  cursor: pointer;
}

.dropdown-item-dot {
  opacity: 0;
  color: #91a5be;
  transition: all 0.2s ease-in-out;
}

.dropdown-item-dot.selected {
  opacity: 1;
}

.icon {
  font-size: 13px;
  color: #91a5be;
  transform: rotate(0deg);
  transition: all 0.2s ease-in-out;
}

.icon.open {
  transform: rotate(90deg);
}
