@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
	--white: #ffffff;
	--darkblue: #2d4764;
}

* {
	box-sizing: border-box;
}

::-webkit-scrollbar {
	width: 5px;
	background: #fafafa;
}

::-webkit-scrollbar-thumb {
	background: #ccc;
	-webkit-border-radius: 5px;
	-webkit-box-shadow: 0px 1px 2px #aaa;
}

::-webkit-scrollbar-thumb:hover {
	background: #aaa;
}

::-webkit-scrollbar-corner {
	background: #ccc;
}

::-webkit-scrollbar-corner:hover {
	background: #aaa;
}

@font-face {
	font-family: 'Nunito';
	font-display: swap;
	src: local('Nunito Regular'), local('Nunito-Regular'),
		url(https://fonts.gstatic.com/s/nunito/v11/XRXV3I6Li01BKofINeaBTMnFcQ.woff2)
			format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
		U+FEFF, U+FFFD;
}

.ant-select-selector {
	border-color: rgba(204, 204, 204, 0.5) !important;
	box-shadow: none !important;
}
.mapboxgl-popup-content {
	padding: 0px;
}
.ant-picker-dropdown {
	z-index: 3000000 !important;
}

@media screen and (max-width: 600px) {
	.lightbox-wrapper img {
		width: 77% !important;
	}
	.onebox {
		display: none;
	}
}

.Toastify__toast-container--top-right {
	top: 11em;
}
.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon
	.MuiAutocomplete-inputRoot {
	width: 590px !important;
	padding-block: 10px;
}

.ant-select-dropdown {
	z-index: 9999 !important;
}

.MuiPaper-elevation1 {
	box-shadow: none !important;
}

.MuiAccordion-root::before {
	background: none !important;
}

.ant-tabs-tab-active div {
	font-weight: bold;
	font-size: 14px;
	color: #28334a !important;
}

.ant-tabs-tab-active div:hover {
	color: none !important;
	/* color: none !important; */
}

.ants-tabs-tab {
	border: none;
}

.ants-tab-nav:first-child {
	margin: 0 !important;
}

.MuiAccordion-root.Mui-expanded {
	margin: 0px !important;
}

.MuiAccordion-root::before {
	display: block !important;
	content: '';
	position: absolute !important;
	left: 0 !important;
	top: 0 !important;
	height: 100% !important;
	width: 2px;
	border: 2px solid black;
}

.MuiAccordion-root.Mui-expanded::before {
	opacity: 1 !important;
}

.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon
	.MuiAutocomplete-inputRoot {
	height: 90%;
}

.MuiAutocomplete-inputRoot[class*='MuiInput-root']
	.MuiAutocomplete-input:first-child {
	height: 100%;
}

.MuiFormControl-root .MuiTextField-root .MuiFormControl-fullWidth {
	height: 100%;
}

.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon
	.MuiAutocomplete-inputRoot {
	/* width: 380px !important; */
	width: 590px !important;
	/* height: 50px !important; */
}

.react-geocoder {
	flex: 1;
	width: 250px;
	height: 30px;
	border: none;
	z-index: 1;
}
.react-geocoder-results {
	transform: translate(-15%, 5%);
	z-index: 1;
}
.react-geocoder-item {
	background-color: olive;
	width: 250px;
	height: 25px;
	z-index: 1;
}
@media screen and (max-width: 600px) {
	.react-geocoder-results {
		transform: translate(-15%, 10px);
	}
	.react-geocoder-item {
		width: 250px;
	}
}

.MuiStepIcon-root.MuiStepIcon-active {
	color: #2d4764 !important;
}

.MuiStepIcon-root.MuiStepIcon-completed {
	color: #2d4764 !important;
}
.ant-drawer .ant-drawer-content {
	width: 100%;
	height: 100%;
	background: #ffffff;
	box-shadow: -2px 4px 10px rgb(0 0 0 / 25%);
	border-radius: 36px 0px 0px 0px;
}
